import { MouseEventHandler } from 'react';
import { Box, Flex, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import T from '../../../Core/Translations';
import { CheckIcon } from '@chakra-ui/icons';

interface SettingBoxProps {
	infoText: string;
	title: string;
	onClick: MouseEventHandler<HTMLElement>;
	selected: boolean;
	disabled?: boolean;
}
export default function ModeSetting({
	infoText,
	title,
	onClick,
	selected,
	disabled,
}: SettingBoxProps) {

	let isIframe = false;
	if (process.env.REACT_APP_PLATFORM === "web" && window.self !== window.top) {
		isIframe = true;
	}
	return (
		<Flex		
			flex={1}
			minHeight={"40px"}			
			w="full"	
			as="button"
			borderRadius="6px"
			onClick={onClick}
			disabled={disabled}
			_disabled={{ cursor: 'default' }}
			p="3px"
			bg={useColorModeValue('white', 'black')}
			alignItems={'center'}
			justifyContent={'center'}
			border={'2px solid'}
			borderColor={useColorModeValue((selected ? '#a6aAaE' : '#E6EAEE'), (selected ? '#ffffff' : '#222'))}
			aria-pressed={selected ? "true" : "false"}
		>
			<Tooltip label={T.get(infoText)} aria-label='A tooltip'>
				<Flex alignItems={'center'} justifyContent={'center'}>
					<Flex w="full" flexDir={{ base: 'column', md: 'row' }}>
						<Text 
							fontSize={isIframe ? "13px" : "13px"} 
							fontWeight={400} 
							color={useColorModeValue("#1A202C", "#fff")}
							>
							{T.get(title)}
						</Text>
						<Flex alignItems={"center"} justifyContent={"center"} ml={[0, 4]}>
							<Flex									
									w="20px"
									h="20px"
									alignItems="center"
									justifyContent="center"
									bg={selected ? "mutedGreen" : '#f0f0f0'}
									borderRadius="50%"
								>
									<CheckIcon color={selected ? '#111' : '#ccc'} fontSize="8px" fill={'#222'} />
							</Flex>
						</Flex>						
					</Flex>
				</Flex>
			</Tooltip>
		</Flex>
	);
}
