import React, { useState, MouseEvent, useRef } from "react";
import useUser from "../../../Core/Hooks/useUser";
import {
  Popover,
  PopoverTrigger,
  Avatar,
  Box,
  PopoverContent,
  Flex,
  Text,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Link,
  ListItem,
  List,
  Portal,
  useColorModeValue,
} from "@chakra-ui/react";
import UserModel from "../../../Models/Users";
import config from "../../../Config";
import BonfireAPI from "../../../Core/Data/Http";
import T from "../../../Core/Translations";
import OrganizationModel from "../../../Models/OrganizationModel";
import { PATCH_USER } from "../../../Models/Users/urls";
import { useDispatch } from "react-redux";
import {
  getUser,
  getUserSubscription,
} from "../../../Core/Data/Store/Actions/user";

import { CLR_PANEL_BG, CLR_PANEL_BG_DARK } from "../../../../Lib/Theme/Light/colors";

function OrgList({
  org,
  organizations,
  user,
  readOnly,
}: {
  org: OrganizationModel;
  organizations: OrganizationModel[];
  user: UserModel;
  readOnly: boolean;
}) {
  const allManagedOrgs = organizations?.filter(
    (organization) => organization?.organizationType?.managedBy
  );

  const dispatch = useDispatch();

  // if the parent org does not contain the user as owner then we should show the tree structure
  const orgIsOwnerOfParentOrg =
    org?.organizationType?.managedBy &&
    organizations
      .find((foundOrg) => foundOrg.id === org?.organizationType?.managedBy)
      ?.isOwner(user.id);

  if (orgIsOwnerOfParentOrg) {
    return null;
  }

  const changeSelectedOrganization = async (orgId: string) => {
    if (readOnly) return;
    await BonfireAPI.patch(PATCH_USER.path, {
      selectedOrganizationId: orgId,
    }).then(() => {
      dispatch(getUser());
      dispatch(getUserSubscription());
    });
  };

  const managedOrgsForOrg = allManagedOrgs.filter(
    (managedOrg) =>
      managedOrg?.organizationType?.managedBy === org.id &&
      managedOrg?.isOwner(user.id)
  );

  return (
    <>
      {((readOnly && org.id === user.selectedOrganizationId) || !readOnly) && <ListItem
        onClick={() => changeSelectedOrganization(org.id)}
        cursor={!readOnly ? "pointer" : "default"}
        borderRadius="13px"
        padding="9px 16px"
        _hover={!readOnly ? { bg: useColorModeValue(CLR_PANEL_BG, CLR_PANEL_BG_DARK) } : {}}
        bgColor={org.id === user.selectedOrganizationId ? '#cccccc55' : 'transparent'}
      >
        <Text variant="beTextDescription">{org.name}</Text>
      </ListItem>}
      {managedOrgsForOrg.length > 0 && (
        <List position="relative" ml="16px" mt="5px" mb="5px">
          {managedOrgsForOrg.map((managedOrg, index) => (
            (((readOnly && managedOrg.id === user.selectedOrganizationId) || !readOnly) && <ListItem
              height="2em"
              borderLeft={
                managedOrgsForOrg.length > 1 &&
                index + 1 !== managedOrgsForOrg.length
                  ? "1px solid #D5DDE4"
                  : "none"
              }
              onClick={() => changeSelectedOrganization(managedOrg.id)}
              cursor="pointer"
              key={managedOrg.id}
            >
              <Box
                width="15px"
                borderBottomLeftRadius="6px"
                position="absolute"
                height="1em"
                borderLeft="1px solid #D5DDE4"
                borderBottom="1px solid #D5DDE4"
                left="0px"
              />
              <Text
                isTruncated
                bgColor={
                  managedOrg.id === user.selectedOrganizationId
                    ? '#cccccc55'
                    : 'transparent'
                }
                p="5px"
                marginLeft="20px"
                _hover={{ bg: useColorModeValue(CLR_PANEL_BG, CLR_PANEL_BG_DARK) }}
                borderRadius="9px"
                variant="beTextDescription"
              >
                {managedOrg.name}
              </Text>
            </ListItem>)
          ))}
        </List>
      )}
    </>
  );
}

export default function UserAvatar({ 
  disableOptionalItems = false, 
  readOnly = false 
}: { 
  disableOptionalItems?: boolean, 
  readOnly?: boolean 
}) {
  const { user, organizations } = useUser();
  const popoverRef = useRef<HTMLDivElement>(null);

  const [viewingOrgs, setViewingOrgs] = useState(readOnly || false);

  const logout = async () => {
    await BonfireAPI.logout();

    setTimeout(() => {
      const portalUrl = window.location.origin;

      const data = { successRedirect: `${portalUrl}/` };
      const loginData = btoa(JSON.stringify(data));

      window.location.href = `${config.login.url}/login?t=${loginData}&p=aipro`;
    }, 500);
  };

  const orgChange = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (readOnly) return;
    setViewingOrgs(!viewingOrgs);
  };
  
  // Popover uses visibility: hidden; to hide the element, and is readable by screen readers even closed.
  // It uses visibility because the element can have some effects when showing or delayed closing and this is why it uses visibility: hidden; which conflicts with screen readers reading it even when closed.
  // With this we make sure it's hidden (display: none) and also adding aria-hidden attribute when hidden.
  const popoverVisible = (show: boolean) => {
    if (!popoverRef.current) return;
    popoverRef.current.style.display = show ? '' : 'none';
    popoverRef.current.ariaHidden = show ? "false" : "true";
  }

  return (
    <Popover placement="top-end" onClose={() => { setViewingOrgs(readOnly || false); popoverVisible(false); }} onOpen={() => popoverVisible(true)}>
      <PopoverTrigger>
        <Box as="button">
          <Avatar
            variant="beVariant"
            name={(user as UserModel)?.name?.charAt(0)}
            src={(user as UserModel)?.avatarUrl}
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent top="-50px" w={{ base: "100vw", md: "250px" }} ref={popoverRef}>
          <PopoverHeader pr="16px" pt="16px" pl="16px">
            <Flex alignItems="center" textAlign="left">
              <Avatar
                variant="beVariant"
                name={user?.name?.charAt(0)}
                src={(user as UserModel)?.avatarUrl}
              />
              <Box ml="15px">
                <Text
                  css={{
                    "&:first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                  fontWeight="400"
                  fontSize="16px"
                >
                  {user?.name}
                </Text>
                <Text
                  isTruncated
                  maxW={{ sm: "unset", md: "150px" }}
                  variant="beTextDescription"
                >
                  {user?.email}
                </Text>
              </Box>
            </Flex>
          </PopoverHeader>
          <PopoverBody
            pl="16px"
            pr="16px"
            display="flex"
            flexDir="column"
            gap="12px"
          >
            <Text
              as="button"
              onClick={orgChange}
              textAlign="left"
              variant="beTextDescription"
            >
              {viewingOrgs
                ? readOnly ? T.get('Current organization') : T.get("Close change organization")
                : T.get("Change organization")}
            </Text>
            {!disableOptionalItems && <Link
              display={viewingOrgs ? "none" : "block"}
              href={`${config.access.url}/user/profile`}
              target="_blank"
            >
              <Text variant="beTextDescription">
                {T.get("Go to profile settings")}
              </Text>
            </Link>}
            <List
              display={viewingOrgs ? "block" : "none"}
              overflowY="auto"
              maxH="300px"
            >
              {organizations?.map((org) => (
                <OrgList
                  org={org}
                  organizations={organizations}
                  user={user}
                  key={org.id}
                  readOnly={readOnly}
                />
              ))}
            </List>
            {/* do not display on prod us <Link href={config.oldPortal.url} onClick={closeUserMenuPopup}>
										<Text variant="beTextDescription">
											{Locale.get('Sign into Previous Portal')}
										</Text>
									</Link>*/}
          </PopoverBody>
          {!disableOptionalItems && <PopoverFooter pl="16px" pr="16px" pb="16px">
            <Box as="button" onClick={logout}>
              <Text variant="beTextDescription" color="danger">
                {T.get("Sign out")}
              </Text>
            </Box>
          </PopoverFooter>}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
