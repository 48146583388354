import {APP_OPENED, SESSION_INFORMATION_OPENED, CAPTIONS_SETTINGS_CHANGED} from '../Constants/general';

export function setAppOpened() {
  return {type: APP_OPENED, payload: true};
}

export function toggleSessionInformationDialog(payload: boolean) {
  return {type: SESSION_INFORMATION_OPENED, payload};
}

export function setCaptionSettings(payload: any) {
  return {type: CAPTIONS_SETTINGS_CHANGED, payload};
}
