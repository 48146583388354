const settingsKey = 'caption-settings';

const defaultSettings = {
    theme: "",
    font: "Arial",
    size: 14,
};

export const loadCaptionSettings = () => {
    try {
        const data = localStorage.getItem(settingsKey)
        if (data === null) {
            return defaultSettings;
        }
        return JSON.parse(data);
    } catch (exc) {
        return defaultSettings;
    }
};

export const saveCaptionSettings = (data: any) => {
    localStorage.setItem(settingsKey, JSON.stringify(data));
};
