import {
  Box,
  Center,
  Button,
  List,
  Spinner,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import T from "../../../Core/Translations";
import { useNavigate } from "react-router-dom";
import ListItem from "../../../Components/ListItem/Web";
import SessionsModel from "../../../Models/Sessions";
import useDashboard from "../../../Core/Hooks/useDashboard";
import SubscriptionAlert from "../../../Components/SubscriptionAlert/Web";
import UserAvatar from "../../../Components/Avatar/Web";
import { CLR_PANEL_BG, CLR_PANEL_BG_DARK } from "../../../../Lib/Theme/Light/colors";

const Dashboard = () => {
  const navigate = useNavigate();

  const { sessions, user, isLoading, subscriptionActive } = useDashboard();

  const sessionClicked = (session: SessionsModel) => {
    navigate(`/session-joined/${session.sessionId}`, {
      state: {
        sessionId: session.sessionId,
        type: session.eventType,
      },
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Center
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Flex
        w={["full", "full", "800px"]}
        flexDir="column"
        backgroundColor={"#fefefe"}
        padding="23px"
        borderRadius={8}
        borderWidth={[0, 0, 1]}
        bgColor={useColorModeValue(CLR_PANEL_BG, CLR_PANEL_BG_DARK)}
        h={["100svh", "100svh", "calc(100vh - 80px)"]}
        mt={[0, 0, "40px"]}
      >
        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="beTextHeading">{T.get("Boostlingo AI Pro")}</Text>
            <UserAvatar />
          </Flex>
          <Text mt={2} variant="beTextDescription">
            {T.get(
              `Boostlingo AI Pro provides a seamless experience for creating and joining captioning sessions, and allows easy access to the list of existing sessions, making captioning audio effortless.`
            )}
          </Text>
        </Box>
        <SubscriptionAlert mt={5} />
        <Box mb={10} mt={5} overflowY="auto">
          {sessions.length === 0 ? (
            <Center p={5} bg={useColorModeValue("white", "black")} borderRadius={4}>
              {T.get(
                "Currently, you do not have any sessions available. Join or Create a New Session"
              )}
            </Center>
          ) : (
              <List>
                {sessions.map((liveSession: any) => (
                  <ListItem
                    key={liveSession.eventId}
                    text={liveSession.name}
                    onClick={() => {
                      sessionClicked(liveSession);
                    }}
                  />
                ))}
              </List>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" mt="auto">
          <Button
            variant="bePrimary"
            w="full"
            mr={2}
            onClick={() => navigate("/join-session")}
          >
            {T.get("Join session")}
          </Button>
          <Button
            variant="beSecondary"
            w="full"
            isDisabled={!subscriptionActive}
            ml={2}
            onClick={() => navigate("/create-session")}
          >
            {T.get("Create session")}
          </Button>
        </Box>
      </Flex>
    </Center>
  );
};

export default Dashboard;
