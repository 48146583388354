import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Navigation";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "./Lib/Theme/Light/theme";
import { Provider } from "react-redux";
import store from "./AppBase/Core/Data/Store";
import Clarity from '@microsoft/clarity';
import Config from "./AppBase/Config";

const projectId = Config.clarity.projectId;
if (projectId) {
  if (document.location.href.indexOf("/conference") < 0) {
    Clarity.init(projectId);
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <ChakraProvider theme={theme}>
      <Provider store={store}>      
        <App />
      </Provider>
    </ChakraProvider>
  </>  
);
