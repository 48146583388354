import {
  Box,
  Text,
  Center,
  Flex,
  Divider,
  Button,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import T from "../../../Core/Translations";
import { EmailIcon } from "@chakra-ui/icons";
import config from "../../../Config";
import { CLR_PANEL_BG, CLR_PANEL_BG_DARK } from "../../../../Lib/Theme/Light/colors";
import { isInTeams } from "../../../../Lib/Utils/routes";

export default function ConsentScreen() {

  const sendToParent = (message: any) => {
    if (!window.parent) return; // we have no parent window
    console.log("Sending message to parent", message)
    window.parent.postMessage(message, "*");
  };

  const allow = () => sendToParent({ type: "consent-allow" });
  //const cancel = () => sendToParent({ type: "consent-cancel" });

  const isTeams = isInTeams();

  return (
    <Center
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      mt={[0, 0, 20]}
    >
      <Flex
        w={["full", "full", "800px"]}
        h={["calc(100vh)", "calc(100vh)", "full"]}
        bgColor={useColorModeValue(CLR_PANEL_BG, CLR_PANEL_BG_DARK)}
        flexDirection="column"
        padding={[8, 8, 10]}
        borderRadius={8}
        borderWidth={[0, 0, 1]}
        overflow="auto"
      >
        <Text variant="beTextHeading" fontSize="24px" textAlign="center">
          {T.get("Access Boostlingo AI Pro")}
        </Text>
        <Divider mt="23px" mb="23px" />
        <Flex alignItems="center" gap="12px" mt="12px">
          <EmailIcon color="textDescription" fontSize="20px" />
          <Text variant="beTextDescription">
            {T.get(
              "We need your email to integrate with Boostlingo AI Pro"
            )}
          </Text>
        </Flex>
        <Divider mt="23px" mb="23px" />
        <Flex gap="23px">
          {/*
          <Button variant="beOutline" w="full" size="sm" onClick={cancel}>
            {T.get("Cancel")}
          </Button>
          */}
          <Button variant="bePrimary" w="full" size="sm" onClick={allow}>
            {T.get("Allow")}
          </Button>
        </Flex>
        <Divider mt="23px" mb="23px" />
        <Text>
          <Text display="block" variant="beTextDescription">
            {T.get(
              "By accepting this integration, you will be sharing your account email with Boostlingo AI Pro and an account will be created for you. In addition by accepting this integration, you agree to the"
            )}
            {" "}
            {
              isTeams || window.location.ancestorOrigins.length === 0 ?
                <Link href={config.privacyPolicyUrl} target="_blank" >
                  <Text ml="4px" display="inline" textDecoration="underline">
                    {T.get("Privacy Policy")}
                  </Text>
                </Link>
                :
                <Text onClick={() => sendToParent(config.privacyPolicyUrl)} display="inline" textDecoration="underline" cursor={'pointer'}>
                  {T.get("Privacy Policy")}
                </Text>
            }
            <Text ml="4px" mr="4px" display="inline-block">
              {T.get(" and ")}
            </Text>
            {
              isTeams || window.location.ancestorOrigins.length === 0 ?
                <Link href={config.termsOfServiceUrl} target="_blank">
                  <Text display="inline-block" textDecoration="underline">
                    {T.get("Terms of Service")}
                  </Text>
                </Link>
                :
                <Text onClick={() => sendToParent(config.termsOfServiceUrl)} display="inline" textDecoration="underline" cursor={'pointer'}>
                  {T.get("Terms of Service")}
                </Text>
            }
          </Text>
        </Text>
      </Flex>
    </Center>
  );
}
