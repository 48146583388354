import config from "../../../../../Config";
import { Box, Button, Text, Divider } from "@chakra-ui/react";
import T from "../../../../../Core/Translations";
import React from "react";
import StarRating from "../../SessionRating/Web";
import BonfireAPI from "../../../../../Core/Data/Http";
import { isInTeams } from "../../../../../../Lib/Utils/routes";

const SessionHasEndedState = ({
  provider,
  sessionId,
  userIsNotGuest,
  sessionType,
}: {
  provider: string;
  sessionId: string;
  userIsNotGuest: boolean;
  sessionType: string;
}) => {
  const goToWebViewer = () => {
    document.location.href = `${config.access.url}/AI-Pro/${sessionId}/annotations`;
  };

  // having two similar methods for rating and feedback submission in one endpoint with change on body
  const onRatingSubmit = async (rating: number) => {
    try {
      await BonfireAPI.post(`/sessions/add-rating/${sessionId}`, { rating }).then((response) => {
      });
    }
    catch (error) {
      console.error('Error submitting rating:', error);
    }
  }

  const onFeedbackSubmit = async (feedback: string) => {
    try {
      await BonfireAPI.post(`/sessions/add-rating/${sessionId}`, { feedback }).then((response) => {
      });
    }
    catch (error) {
      console.error('Error submitting rating:', error);
    }
  }

  const isTeams = sessionType == "teams" || isInTeams();
  const isZoom = sessionType == "zoom";
  const isInExternalHostApp = isTeams || isZoom;

  return (
    <Box m="auto" w="70%">
      <Text variant="beTextHeading" textAlign="center" fontSize="24px">
        {T.get("This session has concluded.")}
      </Text>
      <Text variant="beTextDescription" textAlign="center">
        {T.get(
          "Thanks for being part of this session, we hope you enjoyed it."
        )}
      </Text>
      {userIsNotGuest && (!provider || provider === "local") && !isInExternalHostApp && (
        <Button
          onClick={goToWebViewer}
          variant="bePrimary"
          ml="auto"
          mr="auto"
          mt="23px"
          mb="23px"
        >
          {T.get("Go to web viewer")}
        </Button>
      )}
      <Divider m="50px 0" />
      <Text variant="beTextDescription" textAlign={'center'}>How would you rate the session?</Text>
      <StarRating onRatingChange={onRatingSubmit} handleFeedbackSubmit={onFeedbackSubmit} />
    </Box>
  );
};

export default SessionHasEndedState;
