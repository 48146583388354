import { Flex, ListItem, Text, useColorModeValue } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";

import { CLR_PANEL_BG } from "../../../../Lib/Theme/Light/colors";

const Item = ({ text, onClick }: any) => {
  return (
    <ListItem>
      <Flex
        as="button"
        w="full"
        padding="15px 11px"
        borderBottomWidth={2}
        backgroundColor={useColorModeValue("white", "black")}
        borderBottomColor={CLR_PANEL_BG}
        flexDirection={"row"}
        display={"flex"}
        cursor={"pointer"}
        onClick={onClick}
      >
        <Text textAlign="left" fontWeight="400" fontSize="14px" flex={1}>
          {text}
        </Text>
        <FontAwesomeIcon
          icon={faLongArrowRight}
          size={"lg"}
          color={"#4190C3"}
        />
      </Flex>
    </ListItem>
  );
};

export default Item;
