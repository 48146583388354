import BonfireAPI from "../../../../AppBase/Core/Data/Http";
import { CREATE_SESSION_WITH_PROVIDER } from "../../../Models/SessionStatus/urls";
import SessionStatusModel from "../../../Models/SessionStatus";
import {
  PAUSE_SESSION,
  START_SESSION,
  STOP_SESSION,
} from "../../../Models/SessionsAuth/urls";

class CreateSessionViewModel {
  static async processCreateSessionWithProvider(
    type: string,
    options: any
  ): Promise<SessionStatusModel | Error | null> {
    if (type === "zoom") {
      return CreateSessionViewModel.processZoomProvider(
        options.meetingId,
        options.namespace,        
        options.password,
        options.sessionName,
        options.languages,
        options.speakingLanguage,
        options.speechLanguages,
        options.autoStartDisabled,
        options.autoClean,
        options.joinUrl,
        options.sessionAccessType,
        options.dictionaryId,
        options.tags,
        options.sessionDescription
      );
    } else if (type === "teams") {
      return CreateSessionViewModel.processTeamsProvider(
        options.meetingId,
        options.joinUrl,
        options.sessionName,
        options.languages,
        options.speakingLanguage,
        options.speechLanguages,
        options.autoStartDisabled,
        options.autoClean,
        options.sessionAccessType,
        options.dictionaryId,
        options.tags,
        options.sessionDescription
      );
    } else if (type === "boostevents") {
      return CreateSessionViewModel.processBoostEventsProvider(
        options.meetingId,
        options.languages,
        "en"
      );
    } else if (type === "local" || !type) {
      return CreateSessionViewModel.processLocalProvider(
        options.sessionName,
        options.languages,
        options.speakingLanguage,
        options.speechLanguages,
        options.autoStartDisabled,
        options.autoClean,
        options.sessionAccessType,
        options.dictionaryId,
        options.tags,
        options.sessionDescription
      );
    } else if (type === "ondemand") {
      return CreateSessionViewModel.processOnDemandProvider(
        options.meetingId,
        options.sessionName,
        options.languages,
        options.speakingLanguage,
        options.speechLanguages,
        options.autoStartDisabled,
        options.autoClean,
        options.sessionAccessType,
        options.dictionaryId,
        options.tags,
        options.sessionDescription
      );
    }

    return null;
  }

  static async processZoomProvider(
    meetingId: string,
    namespace: string,
    password: string,    
    sessionName: string,
    languages: string[],
    speakingLanguage: string[],
    speechLanguages: string[],
    autoStartDisabled: boolean = false,
    autoClean: boolean,
    joinUrl: string,
    sessionAccessType?: string,
    dictionaryId?: string,
    tags?: string[],    
    sessionDescription?: string,    
  ) {

    return BonfireAPI.request(
      CREATE_SESSION_WITH_PROVIDER,
      SessionStatusModel,
      {
        type: "zoom",
        options: {
          meetingId,
          joinUrl,
          sessionName,
          speakingLanguage: speakingLanguage,
          speechLanguages,
          namespace,
          languages: languages.join(","),
          password,
          autoStartDisabled,
          autoClean,
          sessionAccessType,
          dictionaryId,
          tags,
          sessionDescription,
        },
      }
    );
  }

  static async processTeamsProvider(
    meetingId: string,
    joinUrl: string,
    sessionName: string,
    languages: string[],
    speakingLanguage: string[],
    speechLanguages: string[],
    autoStartDisabled: boolean = false,
    autoClean: boolean,
    sessionAccessType?: string,
    dictionaryId?: string,
    tags?: string[],
    sessionDescription?: string,
  ) {
    return BonfireAPI.request(
      CREATE_SESSION_WITH_PROVIDER,
      SessionStatusModel,
      {
        type: "teams",
        options: {
          meetingId,
          joinUrl,
          sessionName,
          speakingLanguage: speakingLanguage,
          speechLanguages,
          languages: languages.join(","),
          autoStartDisabled,
          autoClean,
          sessionAccessType,
          dictionaryId,
          tags,
          sessionDescription,
        },
      }
    );
  }

  static async processOnDemandProvider(
    meetingId: string,
    sessionName: string,
    languages: string[],
    speakingLanguage: string[],
    speechLanguages: string[],
    autoStartDisabled: boolean = false,
    autoClean: boolean,
    sessionAccessType?: string,
    dictionaryId?: string,
    tags?: string[],
    sessionDescription?: string,
  ) {
    return BonfireAPI.request(
      CREATE_SESSION_WITH_PROVIDER,
      SessionStatusModel,
      {
        type: "ondemand",
        options: {
          meetingId,
          sessionName,
          speakingLanguage: speakingLanguage,
          speechLanguages,
          languages: languages.join(","),
          autoStartDisabled,
          autoClean,
          sessionAccessType,
          dictionaryId,
          tags,
          sessionDescription,
        },
      }
    );
  }

  static async processBoostEventsProvider(
    meetingId: string,
    languages: string[],
    speakingLanguage: string
  ) {
    return BonfireAPI.request(
      CREATE_SESSION_WITH_PROVIDER,
      SessionStatusModel,
      {
        type: "boostevents",
        options: {
          meetingId,
          languages: languages.join(","),
        },
      }
    );
  }

  static async processLocalProvider(
    sessionName: string,
    languages: string[],
    speakingLanguage: string[],
    speechLanguages: string[],
    autoStartDisabled: boolean = false,
    autoClean: boolean,
    sessionAccessType?: string,
    dictionaryId?: string,
    tags?: string[],
    sessionDescription?: string,
  ) {
    return BonfireAPI.request(
      CREATE_SESSION_WITH_PROVIDER,
      SessionStatusModel,
      {
        type: "local",
        options: {
          sessionName,
          speakingLanguage: speakingLanguage,
          speechLanguages,
          autoStartDisabled,
          languages: languages.join(","),
          autoClean,
          sessionAccessType,
          dictionaryId,
          tags,
          sessionDescription,
        },
      }
    );
  }

  static async stopSession(eventId: string) {
    return BonfireAPI.request(STOP_SESSION, SessionStatusModel, { eventId });
  }

  static async pauseSession(sessionId: string) {
    return BonfireAPI.request(PAUSE_SESSION, SessionStatusModel, { sessionId });
  }

  static async startSession(sessionId: string) {
    return BonfireAPI.request(START_SESSION, SessionStatusModel, { sessionId });
  }
}

export default CreateSessionViewModel;
